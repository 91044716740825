<template>
  <v-container fluid>
    <v-row dense class="mb-4 mx-1">
      <v-col cols="12">
        <v-row dense class="mb-3">
          <v-col>
            <h1 class="toolbar--text">{{ $t('News') }}</h1>
          </v-col>
        </v-row>
        <v-divider />
        <v-row dense>
          <v-col>
            <Loader v-if="!iframe.loaded" class="pt-16 pb-10"/>
            <iframe
              v-show="iframe.loaded"
              ref="news"
              :src="iframe.src"
              frameborder="0"
              class="white--text news pa-0 mt-3"
              @load="load"
            >
            </iframe>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loader from '@/components/molecules/Loader'
import axios from 'axios'

export default {
  name:'News',
  components: {
    Loader,
  },
  data: () => ({
    loading: true,
    iframe: {
      src: '/news.html',
      loaded: false,
    },
  }),
  methods: {
    load() {
      this.iframe.loaded = true
    },
  },
}
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/variables.scss";
  .news{
    height: 900px;
    color: white;
    width: 100%;
    overflow-y: hidden;
  }

</style>
